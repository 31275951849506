.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

code {
  background-color: yellow;
}

.App-header {
  background-color: #2a2d33;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  padding-top: 40px;
  padding-bottom: 100px;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }

}

.font-face-heebo {
  font-family: "Heebo";
}

.card {
  border-radius: 5px !important;
  border: 1px solid !important;
}

.button-group {
  /* show 2 buttons horizontally*/
  display: flex;
  align-items:center;
  /* flex-direction: row; */
  /* flex-wrap: nowrap; */
  /* flex: 1 */
}

